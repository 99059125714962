import type { UserRoles } from '@/constants'
import type { PageInfo, BasePaginationApiParams } from './PageInfo'

export interface CompanyUser {
    active: boolean
    createdAt: string
    deactivatedAt: string
    emailAddress: string
    firstName: string
    fullName: string
    lastLoginAt: string
    lastName: string
    primaryRole: string
    username: string
}

export interface GetCompanyUsersResp {
    data: CompanyUser[]
    message: string
    pageInfo: PageInfo
}

export interface GetCompanyUsersSummaryResp {
    $schema: string
    data: UsersSummaryInfo
    message?: string
}

export interface CompanyUsersApiParams extends BasePaginationApiParams<CompanyUsersOrderByOptions> {
    active?: string
    createdAtAfter?: string
    createdAtBefore?: string
    deactivatedAtAfter?: string
    deactivatedAtBefore?: string
    emailContains?: string
    firstNameContains?: string
    inactive?: string
    lastLoginAfter?: string
    lastLoginBefore?: string
    lastNameContains?: string
    search?: string
    role?: string | null
    usernameContains?: string
}

export interface TeamDirectoryQuery {
    page: number
    size: number
    orderBy: string | undefined
    sortOrder: number | undefined
    first: number
}

export type UserRole =
    | UserRoles.Client
    | UserRoles.ClientAdmin
    | UserRoles.Staff
    | UserRoles.Submitter
    | UserRoles.Submitter
    | UserRoles.Vendor

export interface UserRoleInfo {
    primaryRole: string
    count: number | undefined
}

export interface UsersSummaryInfo {
    usersByRole: UserRoleInfo[]
}

/**
 * Defines possible `orderBy` options for the Company User API.
 */
export enum CompanyUsersOrderByOptions {
    ActiveAsc = 'active',
    ActiveDesc = '-active',
    CreatedAtAsc = 'createdAt',
    CreatedAtDesc = '-createdAt',
    DeactivatedAtAsc = 'deactivatedAt',
    DeactivatedAtDesc = '-deactivatedAt',
    EmailAddressAsc = 'emailAddress',
    EmailAddressDesc = '-emailAddress',
    FirstNameAsc = 'firstName',
    FirstNameDesc = '-firstName',
    FullNameAsc = 'fullName',
    FullNameDesc = '-fullName',
    LastLoginAtAsc = 'lastLoginAt',
    LastLoginAtDesc = '-lastLoginAt',
    PrimaryRoleAsc = 'primaryRole',
    PrimaryRoleDesc = '-primaryRole',
    TypeAsc = 'type',
    TypeDesc = '-type',
    UsernameAsc = 'username',
    UsernameDesc = '-username'
}
