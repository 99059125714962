import { createRouter, createWebHistory, type Router } from 'vue-router'
// ignore-eslint
import LoginPageComponent from './components/LoginPageComponent.vue'
import ForgotPasswordComponent from './components/ForgotPasswordComponent.vue'
import ResetPasswordComponent from './components/ResetPasswordComponent.vue'
import SetPasswordComponent from './components/SetPasswordComponent.vue'

export const loginPageRoutes = [
    {
        path: '/login',
        component: LoginPageComponent,
        children: [
            {
                path: '',
                name: 'LoginPage',
                component: LoginPageComponent
            },
            {
                path: 'password-reset',
                name: 'PasswordReset',
                component: ForgotPasswordComponent
            },
            {
                path: 'new-password',
                name: 'ResetPassword',
                component: ResetPasswordComponent
            },
            {
                path: 'set-password',
                name: 'SetPassword',
                component: SetPasswordComponent
            }
        ]
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'LoginPage') {
    router = createRouter({
        history: createWebHistory(),
        routes: loginPageRoutes
    })
}
