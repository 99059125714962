import { createRouter, createWebHistory, type Router } from 'vue-router'
// ignore-eslint
import HelpCenter from './HelpCenter/HelpCenter.vue'
import VideoLibrary from './HelpCenter/VideoLibrary/VideoLibrary.vue'
import HelpCenterHome from './components/HelpCenterHome.vue'

export const helpCenterViewRoutes = [
    {
        path: '/help-center',
        name: 'HelpCenterView',
        component: HelpCenter,
        children: [
            {
                path: '',
                name: 'HelpCenterHome',
                component: HelpCenterHome
            },
            {
                path: 'video-library',
                name: 'VideoLibrary',
                component: VideoLibrary
            }
        ]
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'HelpCenterView') {
    router = createRouter({
        history: createWebHistory(),
        routes: helpCenterViewRoutes
    })
}