import httpClient from './httpClient'
import {
    type ContentTag,
    type ContentTagsResp,
    type DeleteTagErrResp,
    type PutContentTagResp,
    type TagFilterApiParams,
    type PostContentTagResp
} from '@/interfaces/Tag'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'

const getTags = async (brandId: string, params?: TagFilterApiParams): Promise<ContentTagsResp> => {
    // To be removed after API update
    if (params?.orderBy) {
        if (params.orderBy === 'otherBrandCount') {
            params.orderBy = 'otherCount'
        } else if (params.orderBy === '-otherBrandCount') {
            params.orderBy = '-otherCount'
        }
    }
    try {
        const response: AxiosResponse<ContentTagsResp> = await httpClient.get<ContentTagsResp>(
            `/brands/${brandId}/tag-summaries`,
            {
                params: { ...params }
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const updateTag = async (brandId: string, tagId: number, tagData: Partial<ContentTag>): Promise<PutContentTagResp> => {
    try {
        const response: AxiosResponse<PutContentTagResp> = await httpClient.put<PutContentTagResp>(
            `/brands/${brandId}/tags/${tagId}`,
            tagData
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const deleteTag = async (brandId: string, tagId: number): Promise<void> => {
    try {
        await httpClient.delete<number | DeleteTagErrResp>(`/brands/${brandId}/tags/${tagId}`)
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const createTag = async (brandId: string, tag: Partial<ContentTag>): Promise<PostContentTagResp> => {
    try {
        const response: AxiosResponse<PostContentTagResp> = await httpClient.post<PostContentTagResp>(
            `/brands/${brandId}/tags`,
            tag
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { getTags, updateTag, deleteTag, createTag }
