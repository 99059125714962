<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

const props = defineProps({
    score: { type: Number, required: true }
})

const scoreIconColor = ref('')
const scoreTextColor = ref('')

const roundedScore = computed(() => parseInt(props.score.toFixed(0)))

onMounted(() => {
    if (roundedScore.value >= 90) {
        scoreTextColor.value = 'text-green-600 dark:text-green-400'
        scoreIconColor.value = 'text-green-200 dark:text-green-800'
    } else if (roundedScore.value >= 75) {
        scoreTextColor.value = 'text-yellow-800 dark:text-yellow-200'
        scoreIconColor.value = 'text-yellow-500 dark:text-yellow-200'
    } else {
        scoreTextColor.value = 'text-red-600 dark:text-red-500'
        scoreIconColor.value = 'text-red-600 dark:text-red-500'
    }
})
</script>

<template>
    <div class="flex items-center gap-2">
        <FaIcon icon="fas fa-circle" class="rounded-full border border-white" :class="scoreIconColor"></FaIcon>
        <span :class="[scoreTextColor]">{{ score.toFixed(0) }} %</span>
    </div>
</template>
