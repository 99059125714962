import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
import type { VideosResponse, HelpCenterApiParams } from '@/interfaces/HelpCenter'

const getVideos = async (params?: HelpCenterApiParams): Promise<VideosResponse> => {
    try {
        const response: AxiosResponse<VideosResponse> = await httpClient.get<VideosResponse>(`/helpcenter/videos`, {
            params
        })
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { getVideos }
