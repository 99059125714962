<template>
    <TableFilterDateRange
        name="deactivatedAt"
        label="Inactive since"
        :start-date="deactivatedAtAfter"
        :end-date="deactivatedAtBefore"
        @update-filter="onUpdateFilter"
        @remove-filter="onRemoveFilter"
    />
</template>

<script setup lang="ts">
import TableFilterDateRange, { type DateRangeFilter } from '@/components/dataTable/tableFilter/TableFilterDateRange.vue'

defineProps({
    deactivatedAtBefore: {
        type: String,
        default: null
    },
    deactivatedAtAfter: {
        type: String,
        default: null
    }
})

const emit = defineEmits(['update-deactivated-at-filter', 'remove-deactivated-at-filter'])

const onUpdateFilter = (filter: DateRangeFilter) => {
    emit('update-deactivated-at-filter', {
        deactivatedAtAfter: filter.startDate,
        deactivatedAtBefore: filter.endDate
    })
}

const onRemoveFilter = () => {
    emit('remove-deactivated-at-filter')
}
</script>
