<template>
    <TableFilterDateRange
        name="lastLogin"
        label="Last Login"
        :start-date="lastLoginAfter"
        :end-date="lastLoginBefore"
        @update-filter="onUpdateFilter"
        @remove-filter="onRemoveFilter"
    />
</template>

<script lang="ts" setup>
import TableFilterDateRange, { type DateRangeFilter } from '@/components/dataTable/tableFilter/TableFilterDateRange.vue'

defineProps({
    lastLoginAfter: {
        type: String || null,
        default: null
    },
    lastLoginBefore: {
        type: String || null,
        default: null
    }
})

const emit = defineEmits(['update-last-login-filter', 'remove-last-login-filter'])

const onUpdateFilter = (filter: DateRangeFilter) => {
    emit('update-last-login-filter', {
        lastLoginAfter: filter.startDate,
        lastLoginBefore: filter.endDate
    })
}

const onRemoveFilter = () => {
    emit('remove-last-login-filter')
}
</script>
