<template>
    <Toast></Toast>
    <VideoLibraryHeader />
    <VideoLibraryDataView />
</template>
<script setup lang="ts">
import VideoLibraryDataView from '../../components/VideoLibrary/VideoLibraryDataView.vue'
import VideoLibraryHeader from '../../components/VideoLibrary/VideoLibraryHeader.vue'

import { useHelpCenterStore } from '@/stores/helpCenter'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import {
    HelpCenterChannel,
    HelpCenterFeature,
    HelpCenterOrderByOptions,
    HelpCenterType,
    type GetHelpCenterApiParamsReq
} from '@/interfaces/HelpCenter'
import { useToast } from 'primevue/usetoast'
import type { TableState } from '@/interfaces/Table'
import { isValueInEnum, validateAndComputeOrderBy } from '@/utils/helpers'
import { useRouteValidation } from '@/composables/useRouteValidation'

const helpCenterStore = useHelpCenterStore()
const { currentApiParams } = storeToRefs(helpCenterStore)

const videoLibraryState = ref<TableState<HelpCenterOrderByOptions>>({
    page: 1,
    size: 25,
    orderBy: HelpCenterOrderByOptions.PublishTimeAsc,
    sortOrder: -1,
    first: 0
})

const toast = useToast()

function decodeQueryToState(query: any) {
    decodeQueryToVideoLibraryState(query)
    decodeQueryToFilterState(query)
}

function constructExpectedQuery() {
    return constructVideoLibraryQuery()
}

function onValidRoute() {
    loadLazyData()
}

useRouteValidation({
    decodeQueryToState,
    constructExpectedQuery,
    onValidRoute,
    currentApiParams
})

// Functions for decoding query parameters
function decodeQueryToVideoLibraryState(query: any) {
    const { page, orderBy, sortOrder } = query
    videoLibraryState.value.page = validatePage(page)
    videoLibraryState.value.orderBy = validateOrderBy(orderBy)
    videoLibraryState.value.sortOrder = validateSortOrder(sortOrder)
    videoLibraryState.value.first = (videoLibraryState.value.page - 1) * videoLibraryState.value.size
}

function validatePage(page: any) {
    const pageNumber = parseInt(page)
    return isNaN(pageNumber) || pageNumber < 1 ? 1 : pageNumber
}

function validateOrderBy(orderBy: string | undefined): HelpCenterOrderByOptions {
    if (!orderBy || typeof orderBy !== 'string') {
        return HelpCenterOrderByOptions.PublishTimeAsc
    }
    const validOrderBy = isValueInEnum(orderBy, HelpCenterOrderByOptions)
    return validOrderBy ? orderBy  as HelpCenterOrderByOptions : HelpCenterOrderByOptions.PublishTimeAsc
}

function validateSortOrder(sortOrder: any) {
    return sortOrder === 'asc' ? 1 : -1
}

function validateCommaSeparatedEnum<T extends Record<string, string>>(input: string, enumType: T): boolean {
    if (!input) {
        return false
    }
    const inputArray = input.split(',')
    const validValues = Object.values(enumType)
    return inputArray.every((value) => validValues.includes(value))
}

function decodeQueryToFilterState(query: any) {
    const { search, channel, feature, type } = query
    if (search) {
        helpCenterStore.updateCurrentApiParams({ search: search })
    } else {
        helpCenterStore.removeCurrentApiParams(['search'])
    }

    if (validateCommaSeparatedEnum(channel, HelpCenterChannel)) {
        helpCenterStore.updateCurrentApiParams({ includeChannels: channel })
    } else {
        helpCenterStore.removeCurrentApiParams(['includeChannels'])
    }

    if (validateCommaSeparatedEnum(feature, HelpCenterFeature)) {
        helpCenterStore.updateCurrentApiParams({ includeFeatures: feature })
    } else {
        helpCenterStore.removeCurrentApiParams(['includeFeatures'])
    }

    if (validateCommaSeparatedEnum(type, HelpCenterType)) {
        helpCenterStore.updateCurrentApiParams({ includeTypes: type })
    } else {
        helpCenterStore.removeCurrentApiParams(['includeTypes'])
    }
}

function constructVideoLibraryQuery() {
    const updatedQueryParams: any = {
        orderBy: videoLibraryState.value.orderBy,
        sortOrder: videoLibraryState.value.sortOrder === -1 ? 'dsc' : 'asc',
        page: videoLibraryState.value.page.toString()
    }
    if (currentApiParams.value.search) {
        updatedQueryParams.search = currentApiParams.value.search
    }
    if (currentApiParams.value.includeChannels) {
        updatedQueryParams.channel = currentApiParams.value.includeChannels
    }
    if (currentApiParams.value.includeFeatures) {
        updatedQueryParams.feature = currentApiParams.value.includeFeatures
    }
    if (currentApiParams.value.includeTypes) {
        updatedQueryParams.type = currentApiParams.value.includeTypes
    }

    return updatedQueryParams
}

async function loadLazyData() {
    helpCenterStore.updateCurrentApiParams(constructVideoLibraryApiParams())
    try {
        await helpCenterStore.fetchVideos()
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch videos.`,
            life: 3000
        })
    }
}

function constructVideoLibraryApiParams(): GetHelpCenterApiParamsReq {
    const { page, size, orderBy, sortOrder } = videoLibraryState.value
    const computedOrderBy = validateAndComputeOrderBy(orderBy, sortOrder, HelpCenterOrderByOptions)

    const params: GetHelpCenterApiParamsReq = {
        page,
        size,
        orderBy: computedOrderBy as HelpCenterOrderByOptions
    }

    if (currentApiParams.value.search) {
        params.search = currentApiParams.value.search
    }
    if (currentApiParams.value.includeChannels) {
        params.includeChannels = currentApiParams.value.includeChannels
    }
    if (currentApiParams.value.includeFeatures) {
        params.includeFeatures = currentApiParams.value.includeFeatures
    }
    if (currentApiParams.value.includeTypes) {
        params.includeTypes = currentApiParams.value.includeTypes
    }

    return params
}
</script>
