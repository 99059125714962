<template>
    <TableHeader
        :has-selected-rows="false"
        :has-active-filters="hasActiveFilters"
        @clear-all-filters="clearAllFilters"
    >
        <template #filterSection>
            <div class="filters flex gap-2"></div>
        </template>

        <template #right>
            <div class="flex items-center gap-2 mr-2">
                <IconField>
                    <InputIcon class="far fa-search" />
                    <InputText v-model="searchQueryInput" size="small" placeholder="Search user" class="pl-10" />
                </IconField>
            </div>
        </template>
    </TableHeader>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const hasActiveFilters = ref(false)
const searchQueryInput = ref('')

function clearAllFilters(): void {
    console.log('clear all filters')
}
</script>
