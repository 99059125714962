<template>
    <Toast></Toast>
    <DataTable
        :value="users"
        :rows="tableState.size"
        table-style="width: 100%"
        scrollable
        resizable-columns
        column-resize-mode="fit"
        sort-mode="single"
        :sort-field="tableState.orderBy"
        :sort-order="tableState.sortOrder"
        lazy
        :loading="loading"
        :pt="{
            root: { class: ['text-sm relative flex flex-col tags-table-root'] },
            tableContainer: { class: ['flex-1'] },
            header: { class: ['text-xl !p-0'] },
            wrapper: { class: ['flex-1'] },
            bodyrow: { class: ['hover:bg-neutral-600 !h-[50px]'] },
            footer: { class: ['!p-0 !font-normal'] }
        }"
        @sort="onSort"
        @column-resize-end="endColumnResize"
        @mousedown="trackMouseDown"
    >
        <template #header>
            <TeamDirectoryHeader @get-users-summary-info="getUsersSummary" @export-users="exportUsers" />
        </template>

        <Column
            field="fullName"
            header="User"
            :sortable="!resizing"
            :pt="{
                ...columnPt,
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-56 min-w-56 max-w-56'] }
            }"
        >
            <template #body="{ data }">
                <div class="flex justify-between">
                    <div>
                        <span
                            class="user-name cursor-pointer text-sm leading-tight font-medium"
                            @click="copyUserEmail(data)"
                        >
                            {{ data.fullName }}
                        </span>
                        <br />
                        <span class="text-xs leading-none font-normal text-surface-700">{{ data.emailAddress }}</span>
                    </div>
                    <div class="user-controls">
                        <Button class="focus:outline-none !py-2" text rounded size="small" @click="copyUserEmail(data)">
                            <FaIcon icon="far fa-copy" class="text-surface-800"></FaIcon>
                        </Button>
                    </div>
                </div>
            </template>
        </Column>

        <Column
            field="primaryRole"
            header="Role"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <div class="flex flex-col items-start">
                    <span>{{ userRoleLabels[data.primaryRole as keyof typeof userRoleLabels] }}</span>
                    <span v-if="isVendor(data.primaryRole)" class="text-xs text-surface-700 leading-none">
                        Limited access
                    </span>
                </div>
            </template>
        </Column>

        <Column
            field="active"
            header="Status"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <SelectUserStatus v-if="!isInactiveVendor(data) && canEditUserStatus" :user="data" />
                <div
                    v-else-if="isInactiveVendor(data) && canEditUserStatus"
                    v-tooltip.bottom="'Access provided by sending asset remediation.'"
                    class="gap-2 flex items-center pl-2 py-1"
                >
                    <span class="">Inactive</span>
                    <FaIcon icon="far fa-circle-info" class="standard-inactive-icon text-surface-800 h-[12px]"></FaIcon>
                    <FaIcon icon="fas fa-circle-info" class="hover-inactive-icon h-[12px]"></FaIcon>
                </div>
                <div v-else class="gap-2 flex items-center pl-2 py-1">
                    <span class="">{{ data.active ? 'Active' : 'Inactive' }}</span>
                </div>
            </template>
        </Column>

        <Column
            field="deactivatedAt"
            header="Inactive since"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <span v-if="data.deactivatedAt && dayjs(data.deactivatedAt).isAfter(dayjs('2010-01-01'))">{{
                    dayjs(data.deactivatedAt).format('ll')
                }}</span>
                <span v-else>-</span>
            </template>
        </Column>

        <Column
            field="lastLoginAt"
            header="Last login"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <span v-if="data.lastLoginAt && dayjs(data.lastLoginAt).isAfter(dayjs('2010-01-01'))">{{
                    dayjs(data.lastLoginAt).format('ll')
                }}</span>
                <span v-else>-</span>
            </template>
        </Column>

        <Column
            field="createdAt"
            header="Created"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <span>{{ dayjs(data.createdAt).format('ll') }}</span>
            </template>
        </Column>

        <template #footer>
            <Paginator
                v-model:first="tableState.first"
                :rows="tableState.size"
                :total-records="pageInfo?.totalCount"
                template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageDropdown"
                current-page-report-template="Showing {first} to {last} of {totalRecords}"
                :pt="{
                    root: { class: ['!py-1'] },
                    pcJumpToPageDropdown: {
                        class: ['h-8'],
                        label: { class: ['!p-1.5'] },
                        dropdown: { class: ['w-8'] }
                    },
                    first: { class: ['h-8 !m-0'] },
                    prev: { class: ['h-8 !m-0'] },
                    next: { class: ['h-8 !m-0'] },
                    last: { class: ['h-8 !m-0'] }
                }"
                @page="onPage"
            />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type DataTableSortEvent } from 'primevue/datatable'
import { type PageState } from 'primevue/paginator'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCompanyUserStore } from '@/stores/companyUsersStore'
import TeamDirectoryHeader from './TeamDirectoryHeader.vue'
import SelectUserStatus from './SelectUserStatus.vue'
import { dayjsPlugin } from '@/plugins/dayjs'
import { type CompanyUsersApiParams, type CompanyUser, CompanyUsersOrderByOptions } from '@/interfaces/CompanyUser'
import { useToast } from 'primevue/usetoast'
import { userRoleLabels } from '@/constants'
import { stringToBool, validateAndComputeOrderBy } from '@/utils/helpers'
import { useUserStore } from '@/stores/userStore'
import type { TableState } from '@/interfaces/Table'

const dayjs = dayjsPlugin

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const companyUserStore = useCompanyUserStore()
const {
    users,
    pageInfo,
    loading,
    searchQuery,
    createdAtBefore,
    createdAtAfter,
    lastLoginBefore,
    lastLoginAfter,
    deactivatedAtBefore,
    deactivatedAtAfter,
    role,
    active,
    inactive
} = storeToRefs(companyUserStore)

const columnPt = {
    headerTitle: { class: ['truncate whitespace-nowrap font-normal'] },
    bodyCell: { class: ['whitespace-nowrap h-12 !py-0'] }
}

const router = useRouter()
const toast = useToast()
const route = useRoute()
const companyId = route.params.companyId as string

const tableState = ref<TableState<CompanyUsersOrderByOptions>>({
    page: 1,
    size: 25,
    orderBy: CompanyUsersOrderByOptions.LastLoginAtAsc,
    sortOrder: -1,
    first: 0
})

const resizing = ref(false)

onMounted(() => {
    const query = { ...route.query }
    decodeQueryToTableState(query)
    decodeQueryToFilterState(query)
    updateUrlQueryParams(constructTableQuery(), { routerReplace: true })
    loadLazyData()
})

watch(route, () => {
    const query = { ...route.query }
    decodeQueryToTableState(query)
    decodeQueryToFilterState(query)
    updateUrlQueryParams(constructTableQuery(), { queryReplace: true })
    loadLazyData()
})

// To Do: Move to a composable
function decodeQueryToTableState(query: any) {
    const { page, orderBy, sortOrder } = query
    tableState.value.page = validatePage(page)
    tableState.value.orderBy = validateOrderBy(orderBy)
    tableState.value.sortOrder = validateSortOrder(sortOrder)
    tableState.value.first = (tableState.value.page - 1) * tableState.value.size
}

function decodeQueryToFilterState(query: any) {
    const {
        search,
        createdAtBefore,
        createdAtAfter,
        lastLoginBefore,
        lastLoginAfter,
        deactivatedAtBefore,
        deactivatedAtAfter,
        role,
        active,
        inactive
    } = query
    if (search) {
        companyUserStore.updateSearchQuery(search)
    }
    if (validateDate(createdAtBefore) && validateDate(createdAtAfter)) {
        companyUserStore.updateCreatedAtFilter({
            createdAtBefore: dayjs(createdAtBefore).format('YYYY-MM-DD'),
            createdAtAfter: dayjs(createdAtAfter).format('YYYY-MM-DD')
        })
    } else {
        companyUserStore.removeCreatedAtFilter()
    }

    if (validateDate(lastLoginBefore) && validateDate(lastLoginAfter)) {
        companyUserStore.updateLastLoginFilter({
            lastLoginBefore: dayjs(lastLoginBefore).format('YYYY-MM-DD'),
            lastLoginAfter: dayjs(lastLoginAfter).format('YYYY-MM-DD')
        })
    } else {
        companyUserStore.removeLastLoginFilter()
    }

    if (validateDate(deactivatedAtBefore) && validateDate(deactivatedAtAfter)) {
        companyUserStore.updateDeactivatedAtFilter({
            deactivatedAtBefore: dayjs(deactivatedAtBefore).format('YYYY-MM-DD'),
            deactivatedAtAfter: dayjs(deactivatedAtAfter).format('YYYY-MM-DD')
        })
    } else {
        companyUserStore.removeDeactivatedAtFilter()
    }

    if (role) {
        companyUserStore.updateUserRoleFilter({ role: role })
    } else {
        companyUserStore.removeUserRoleFilter()
    }

    if (active || inactive) {
        companyUserStore.updateUserStatusFilter({ active: stringToBool(active), inactive: stringToBool(inactive) })
    } else {
        companyUserStore.removeUserStatusFilter()
    }
}

function validatePage(page: any) {
    const pageNumber = parseInt(page)
    return isNaN(pageNumber) || pageNumber < 1 ? tableState.value.page : pageNumber
}

function validateOrderBy(orderBy: any) {
    return (orderBy as CompanyUsersOrderByOptions) || tableState.value.orderBy
}

function validateSortOrder(sortOrder: any) {
    return sortOrder === 'asc' || sortOrder === 'dsc' ? (sortOrder === 'dsc' ? -1 : 1) : tableState.value.sortOrder
}

function validateDate(dateString: string): string | null {
    if (!dateString) return null
    const date = dayjs(dateString)
    return date.isValid() ? date.format('YYYY-MM-DD') : null
}

function constructTableQuery() {
    const updatedQueryParams: any = {
        orderBy: tableState.value.orderBy as keyof CompanyUser,
        sortOrder: tableState.value.sortOrder === -1 ? 'dsc' : 'asc',
        page: tableState.value.page
    }

    if (searchQuery.value) {
        updatedQueryParams.search = searchQuery.value
    }
    if (createdAtBefore.value) {
        updatedQueryParams.createdAtBefore = createdAtBefore.value
    }
    if (createdAtAfter.value) {
        updatedQueryParams.createdAtAfter = createdAtAfter.value
    }
    if (lastLoginBefore.value) {
        updatedQueryParams.lastLoginBefore = lastLoginBefore.value
    }
    if (lastLoginAfter.value) {
        updatedQueryParams.lastLoginAfter = lastLoginAfter.value
    }
    if (deactivatedAtBefore.value) {
        updatedQueryParams.deactivatedAtBefore = deactivatedAtBefore.value
    }
    if (deactivatedAtAfter.value) {
        updatedQueryParams.deactivatedAtAfter = deactivatedAtAfter.value
    }
    if (role.value) {
        updatedQueryParams.role = role.value
    }
    if (active.value) {
        updatedQueryParams.active = active.value
    }
    if (inactive.value) {
        updatedQueryParams.inactive = inactive.value
    }

    return updatedQueryParams
}

const loadLazyData = async () => {
    const params = constructTableApiParams()
    try {
        await companyUserStore.fetchUsers(companyId, params)
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch users.`,
            life: 3000
        })
    }
}

const getUsersSummary = async () => {
    const params = constructTableApiParams()
    try {
        // remove role filter
        params.role = undefined
        await companyUserStore.fetchUsersSummary(companyId, params)
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch users summary info.`,
            life: 3000
        })
    }
}

function constructTableApiParams(): CompanyUsersApiParams {
    const { page, size, orderBy, sortOrder } = tableState.value
    const computedOrderBy = validateAndComputeOrderBy(orderBy, sortOrder, CompanyUsersOrderByOptions)

    const params: CompanyUsersApiParams = {
        page,
        size,
        orderBy: computedOrderBy as CompanyUsersOrderByOptions
    }
    if (searchQuery.value) {
        params.search = searchQuery.value
    }
    if (createdAtBefore.value) {
        params.createdAtBefore = createdAtBefore.value
    }
    if (createdAtAfter.value) {
        params.createdAtAfter = createdAtAfter.value
    }
    if (lastLoginBefore.value) {
        params.lastLoginBefore = lastLoginBefore.value
    }
    if (lastLoginAfter.value) {
        params.lastLoginAfter = lastLoginAfter.value
    }
    if (deactivatedAtBefore.value) {
        params.deactivatedAtBefore = deactivatedAtBefore.value
    }
    if (deactivatedAtAfter.value) {
        params.deactivatedAtAfter = deactivatedAtAfter.value
    }
    if (role.value) {
        params.role = role.value
    }
    if (active.value) {
        params.active = active.value.toString()
    }
    if (inactive.value) {
        params.inactive = inactive.value.toString()
    }
    return params
}

const onPage = async (event: PageState) => {
    tableState.value.page = event.page + 1
    tableState.value.size = event.rows
    updateUrlQueryParams({ page: tableState.value.page })
    // loadLazyData()
}
const onSort = async (event: DataTableSortEvent) => {
    tableState.value.orderBy = event.sortField ? (event.sortField.toString() as CompanyUsersOrderByOptions) : undefined
    tableState.value.sortOrder = event.sortOrder !== null ? event.sortOrder : undefined
    tableState.value.page = 1
    tableState.value.first = 0
    updateUrlQueryParams({
        orderBy: tableState.value.orderBy as keyof CompanyUser,
        sortOrder: tableState.value.sortOrder === -1 ? 'dsc' : 'asc',
        page: tableState.value.page
    })
    // loadLazyData()
}

const trackMouseDown = (event: MouseEvent): void => {
    if (event?.target) {
        const target = event.target as HTMLElement
        if (target.getAttribute('data-pc-section') === 'columnresizer') {
            resizing.value = true
        }
    }
}

const endColumnResize = () => {
    setTimeout(() => {
        resizing.value = false
    }, 300)
}

function updateUrlQueryParams(params: any, { queryReplace = false, routerReplace = false } = {}) {
    if (routerReplace) {
        router.replace({ query: queryReplace ? { ...params } : { ...route.query, ...params } })
    } else {
        router.push({ query: queryReplace ? { ...params } : { ...route.query, ...params } })
    }
}

const copyUserEmail = (data: CompanyUser) => {
    navigator.clipboard.writeText(data.emailAddress)
    toast.add({
        severity: 'success',
        summary: 'Copied',
        detail: `Email copied to clipboard.`,
        life: 3000
    })
}

// Watch for changes in filter values
watch(
    [
        searchQuery,
        createdAtBefore,
        createdAtAfter,
        lastLoginBefore,
        lastLoginAfter,
        deactivatedAtBefore,
        deactivatedAtAfter,
        role,
        active,
        inactive
    ],
    () => {
        updateUrlQueryParams(constructTableQuery(), { queryReplace: true })
    },
    { deep: true }
)

const isVendor = (userRole: string) => userRole.toLowerCase() === 'vendor'

const isInactiveVendor = (user: CompanyUser) => isVendor(user.primaryRole) && user.active === false

const exportUsers = async () => {
    const params = constructTableApiParams()
    params.size = pageInfo.value.totalCount
    try {
        await companyUserStore.exportUsers(companyId, params)
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Users exported successfully',
            life: 3000
        })
    } catch (error) {
        if (error instanceof Error) {
            if (error.name === 'AbortError') {
                // Do nothing for AbortError (user cancelled)
                return
            }
            // For other errors, show an error toast
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to export users',
                life: 3000
            })
        }
    }
}

const canEditUserStatus = computed(() => {
    return (
        user.value?.activeRole == 'thirdparty' ||
        user.value?.activeRole == 'staff' ||
        user.value?.activeRole == 'superuser'
    )
})
</script>
<style lang="scss" scoped>
tr[data-pc-section='bodyrow'] {
    .user-controls {
        display: none;
    }

    .hover-inactive-icon {
        display: none;
    }

    &:hover {
        .user-name {
            color: rgb(var(--primary-500));
        }

        .user-controls {
            display: flex;
        }

        .standard-inactive-icon {
            display: none;
        }

        .hover-inactive-icon {
            display: inline-block;
        }
    }

    &[data-p-highlight='true'] {
        background-color: rgba(var(--primary-500) / 0.12);

        td {
            border-bottom: 1px solid rgba(var(--surface-950) / 0.1);
        }
    }
}

.tags-table-root {
    height: calc(100vh - 40px);
}
</style>
