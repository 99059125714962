<template>
    <Select
        :key="user.username"
        v-model="currentUserStatus"
        :pt="{
            root: { class: ['items-center border-none', userActiveClass] },
            label: { class: ['text-sm pl-2 pr-0 py-1'] },
            dropdown: { class: ['w-3 h-3 px-3 text-surface-800'] },
            overlay: { class: ['mt-1 !border border-surface-950/[.1] bg-surface-100'] },
            option: ({ context }: any) => ({
                class: [
                    {
                        '!bg-brand-500/[.12] !text-brand-700': context.focused || (context.focused && context.selected)
                    }
                ]
            })
        }"
        :pt-options="{ mergeProps: true }"
        :dropdown-icon="dropdownIcon"
        :overlay-visible="false"
        :options="userActiveOptions"
        option-label="label"
        option-value="value"
        @focus="toggleSelect"
        @blur="toggleSelect"
        @change="updateUserStatus($event.value)"
    />
</template>

<script lang="ts" setup>
import type { CompanyUser } from '@/interfaces/CompanyUser'
import { useCompanyUserStore } from '@/stores/companyUsersStore'
import { ref, type PropType, computed, watch } from 'vue'

const companyUserStore = useCompanyUserStore()

const props = defineProps({
    user: {
        type: Object as PropType<CompanyUser>,
        required: true
    }
})

const currentUserStatus = ref(props.user.active)

const isOpen = ref(false)

const toggleSelect = () => (isOpen.value = !isOpen.value)

const dropdownIcon = computed(() => (isOpen.value ? 'fa fa-caret-up' : 'fa fa-caret-down'))

const userActiveOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
]

const userActiveClass = computed(() => (props.user.active ? '!bg-green-100' : '!bg-surface-100'))

watch(
    () => props.user,
    (newUser) => {
        currentUserStatus.value = newUser.active
    }
)

const updateUserStatus = async (newActiveStatus: boolean) => {
    if (props.user.active === newActiveStatus) return
    try {
        await companyUserStore.patchUser(props.user.username, { active: newActiveStatus })
    } catch (err) {
        console.error(`Failed to change status for user ${props.user.username}.`)
    }
}
</script>
