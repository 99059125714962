export const userRoleLabels = {
    'client': 'User',
    'clientadmin': 'Admin',
    'staff': 'Staff',
    'submitter': 'Submitter',
    'superuser': 'Super User',
    'thirdparty': 'Third Party',
    'vendor': 'Vendor'
}

export enum UserRoles {
    Client = 'client',
    ClientAdmin = 'clientadmin',
    Staff = 'staff',
    Submitter = 'submitter',
    Superuser = 'superuser',
    Thirdparty = 'thirdparty',
    Vendor = 'vendor',
}