<template>
    <Button
        type="button"
        size="small"
        text
        rounded
        label="Clear filters"
        icon="far fa-xmark"
        icon-pos="right"
        :pt="{
            root: { class: ['h-6 text-brand-500 text-sm flex items-center text-nowrap leading-5 !py-0 !px-2'] },
            label: { class: ['ml1'] },
            icon: { class: ['inline-flex text-xs w-3 mt-0.5 !ml-0 mr-.5'] }
        }"
        @click="emit('clear-all-filters')"
    >
    </Button>
</template>

<script lang="ts" setup>
const emit = defineEmits(['clear-all-filters'])
</script>
