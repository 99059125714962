import type { ApiError } from './ApiError'
import type { PageInfo, BasePaginationApiParams } from './PageInfo'

export interface TagUser {
    username: string
    email: string
    profileUrl: string
}

export interface ContentTag {
    id: number
    name: string
    isActive: boolean
    createdAt: string
    createdBy?: TagUser
    lastAssignedAt?: string
}

export interface TagSummary {
    brandUrl: string
    tag: ContentTag
    averageScore: number
    count: number
    otherBrandCount: number
}

export interface ContentTagsResp {
    $schema: string
    data: TagSummary[]
    pageInfo: PageInfo
}

export interface PutContentTagResp {
    $schema: string
    data: ContentTag
    message?: string
}

export interface TagFilterApiParams extends BasePaginationApiParams<TagsListOrderByOptions> {
    nameContains?: string | undefined
    minScore?: number | null
    maxScore?: number | null
}

export interface TagsListQuery {
    page: number
    size: number
    orderBy: string | undefined
    sortOrder: number | undefined
    first: number
}

export interface ColumnNameMap {
    name: string
    count: string
    averageScore: string
    lastAssignedAt: string
    createdAt: string
    createdBy: string
}

export interface DeleteTagErrResp {
    $schema: string
    detail: string
    errors: ApiError[]
    instance: string
    status: number
    title: string
    type: string
}

export interface PostContentTagResp {
    $schema: string
    data: ContentTag
    message?: string
}

/**
 * Defines possible `orderBy` options for the Tags API.
 */
export enum TagsListOrderByOptions {
    AvgScoreAsc = 'averageScore',
    AvgScoreDesc = '-averageScore',
    CountAsc = 'count',
    CountDesc = '-count',
    CreatedAtAsc = 'createdAt',
    CreatedAtDesc = '-createdAt',
    IdAsc = 'id',
    IdDesc = '-id',
    LastAssignedAtAsc = 'lastAssignedAt',
    LastAssignedAtDesc = '-lastAssignedAt',
    NameAsc = 'name',
    NameDesc = '-name',
    OtherBrandCountAsc = 'otherBrandCount',
    OtherBrandCountDesc = '-otherBrandCount',
    OtherCountAsc = 'otherCount',
    OtherCountDesc = '-otherCount'
}
