import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import helpCenterApi from '@/api/helpCenter.api'
import { HelpCenterOrderByOptions, type GetHelpCenterApiParamsReq, type GetHelpCenterVideosRespData, type HelpCenterState } from '@/interfaces/HelpCenter'
import type { ServiceResponse } from '@/interfaces/Table'

const STORAGE_KEY = 'help-center-recent-searches'
const MAX_RECENT_SEARCHES = 8

const getStoredSearches = (): string[] => {
    try {
        const stored = localStorage.getItem(STORAGE_KEY)
        return stored ? JSON.parse(stored) : []
    } catch (error) {
        console.error('Failed to parse stored searches:', error)
        return []
    }
}

const storeSearches = (searches: string[]): void => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(searches))
    } catch (error) {
        console.error('Failed to store searches:', error)
    }
}

export const useHelpCenterStore = defineStore('helpCenter', () => {
    const state = ref<HelpCenterState>({
        recentSearches: getStoredSearches(),
        helpCenterVideos: [],
        pageInfo: null,
        isLoading: false,
        error: null
    })

    const currentApiParams = ref<GetHelpCenterApiParamsReq>({
        size: 25,
        page: 1,
        orderBy: HelpCenterOrderByOptions.PublishTimeAsc
    })

    // To Do: Add return type, consider removing or make optional arg, replace with "currentApiParams"
    async function fetchVideos(): Promise<ServiceResponse<GetHelpCenterVideosRespData> | void> {
        // Validate search params exist
        if (currentApiParams.value.search) {
            if (!currentApiParams.value.search?.trim()) {
                state.value.helpCenterVideos = []
                state.value.pageInfo = null
                return
            }
        }

        try {
            state.value.isLoading = true
            state.value.error = null

            const response = await helpCenterApi.getVideos({
                ...currentApiParams.value,
            })

            state.value.helpCenterVideos = response.data
            state.value.pageInfo = response.pageInfo || null

            // Store in recent searches
            if (currentApiParams.value.search) {
                if (currentApiParams.value.search.trim()) {
                    const searches = new Set([currentApiParams.value.search, ...state.value.recentSearches])
                    state.value.recentSearches = Array.from(searches).slice(0, MAX_RECENT_SEARCHES)
                    storeSearches(state.value.recentSearches)
                }
            }
        } catch (error) {
            state.value.error = 'Failed to search videos'
            console.error('Search error:', error)
        } finally {
            state.value.isLoading = false
        }
    }

    function updateCurrentApiParams(params: Partial<GetHelpCenterApiParamsReq>): void {
        currentApiParams.value = {
            ...currentApiParams.value,
            ...params
        }
    }

    function removeCurrentApiParams<T extends keyof GetHelpCenterApiParamsReq>(keysToClear: T[]): void {
        keysToClear.forEach((key) => {
            if (key in currentApiParams.value) {
                // Explicitly cast to the correct type
                currentApiParams.value[key] = undefined as GetHelpCenterApiParamsReq[T]
            }
        })
    }

    const filteredSuggestions = computed(() => {
        return state.value.recentSearches.slice(0, MAX_RECENT_SEARCHES)
    })

    const searchValue = computed(() => {
        return currentApiParams.value.search || ''
    })

    return {
        state,
        currentApiParams,
        searchValue,
        fetchVideos,
        updateCurrentApiParams,
        removeCurrentApiParams,
        filteredSuggestions
    }
})
