import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'

export interface LoginResponse {
  success: boolean
  message: string
  data: any
}

export interface UserDataResponse {
  $schema: string;
  data: UserData;
  message?: string;
}

export interface UserData {
  company: string
  email: string
  firstName: string
  lastName: string
  groupClaims: Record<string, any>
  isActive: boolean
  primaryGroupId: string
  roleGroupIds: Record<string, string[]>
  username: string
  activeRole: string
}

const login = async (username: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await httpClient.post('/auth/login', { username, password }, { 
      validateStatus: (status) => status >= 200 && status <= 303,
      withCredentials: true
    })
    return { success: true, message: 'Login successful', data: response.data }
  } catch (error) {
    console.log('login error')
    throw handleApiError(error as AxiosError)
  }
}

const logout = async (): Promise<void> => {
  try {
    await httpClient.post('/auth/logout')
  } catch (error) {
    throw handleApiError(error as AxiosError)
  }
}

const forgotPassword = async (email: string): Promise<void> => {
  try {
    await httpClient.post('/auth/forgot-password', { username: email })
  } catch (error) {
    throw handleApiError(error as AxiosError)
  }
}

const fetchUserData = async (): Promise<UserDataResponse> => {
  try {
    const response: AxiosResponse<UserDataResponse> = await httpClient.get<UserDataResponse>('/users/me')
    return response.data
  } catch (error) {
    throw handleApiError(error as AxiosError)
  }
}

const resetPassword = async (newPassword: string, token: string): Promise<void> => {
  try {
    await httpClient.post('/auth/reset-password', { newPassword, resetToken: token })
  } catch (error) {
    throw handleApiError(error as AxiosError)
  }
}

export default { login, logout, forgotPassword, fetchUserData, resetPassword }