import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import helpCenterApi from '@/api/helpCenter.api'
import type { HelpCenterState, VideoQueryParams } from '@/interfaces/HelpCenter'

const STORAGE_KEY = 'help-center-recent-searches'
const MAX_RECENT_SEARCHES = 8

const getStoredSearches = (): string[] => {
    try {
        const stored = localStorage.getItem(STORAGE_KEY)
        return stored ? JSON.parse(stored) : []
    } catch (error) {
        console.error('Failed to parse stored searches:', error)
        return []
    }
}

const storeSearches = (searches: string[]): void => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(searches))
    } catch (error) {
        console.error('Failed to store searches:', error)
    }
}

export const useHelpCenterStore = defineStore('helpCenter', () => {
    const state = ref<HelpCenterState>({
        recentSearches: getStoredSearches(),
        helpCenterVideos: [],
        pageInfo: null,
        isLoading: false,
        error: null
    })

    const currentParams = ref<VideoQueryParams>({
        size: 25,
        page: 1,
        orderBy: ['publishTime']
    })

    async function fetchVideos(query: string) {
        if (!query.trim()) {
            state.value.helpCenterVideos = []
            state.value.pageInfo = null
            return
        }

        try {
            state.value.isLoading = true
            state.value.error = null

            const response = await helpCenterApi.getVideos({
                ...currentParams.value,
                search: query
            })

            state.value.helpCenterVideos = response.data
            state.value.pageInfo = response.pageInfo || null

            // Store in recent searches
            if (query.trim()) {
                const searches = new Set([query, ...state.value.recentSearches])
                state.value.recentSearches = Array.from(searches).slice(0, MAX_RECENT_SEARCHES)
                storeSearches(state.value.recentSearches)
            }
        } catch (error) {
            state.value.error = 'Failed to search videos'
            console.error('Search error:', error)
        } finally {
            state.value.isLoading = false
        }
    }

    function updateParams(params: Partial<VideoQueryParams>) {
        currentParams.value = {
            ...currentParams.value,
            ...params
        }
    }

    const filteredSuggestions = computed(() => {
        return state.value.recentSearches.slice(0, MAX_RECENT_SEARCHES)
    })

    return {
        state,
        currentParams,
        fetchVideos,
        updateParams,
        filteredSuggestions
    }
})
