import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { App } from 'vue'

export interface FontAwesomeOptions {
    solidIcons?: any[]
    regularIcons?: any[]
    lightIcons?: any[]
}

const addIconsToLibrary = (icons: any[]) => {
    icons.forEach((icon) => library.add(icon))
}

export default {
    install(app: App, options?: FontAwesomeOptions) {
        if (options?.solidIcons) {
            addIconsToLibrary(options.solidIcons)
        }
        if (options?.regularIcons) {
            addIconsToLibrary(options.regularIcons)
        }
        if (options?.lightIcons) {
            addIconsToLibrary(options.lightIcons)
        }

        app.component('FaIcon', FontAwesomeIcon)
        dom.watch()
    }
}
