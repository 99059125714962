<template>
    <TableHeader :has-selected-rows="false" :has-active-filters="hasActiveFilters" @clear-all-filters="clearAllFilters">
        <template #filterSection>
            <div class="filters flex gap-2"></div>
        </template>

        <template #right>
            <div class="flex items-center gap-2 mr-2">
                <IconField>
                    <InputIcon class="far fa-search" />
                    <InputText v-model="searchQueryInput" size="small" placeholder="Search user" class="pl-10" />
                </IconField>
            </div>
        </template>
    </TableHeader>
</template>

<script lang="ts" setup>
import { useHelpCenterStore } from '@/stores/helpCenter'
import { useDebounceFn } from '@vueuse/core'
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue'

const helpCenterStore = useHelpCenterStore()
const { searchValue } = storeToRefs(helpCenterStore)

const hasActiveFilters = ref(false)
const searchQueryInput = ref()

const debouncedSearch = useDebounceFn(
    () => {
        helpCenterStore.updateCurrentApiParams({ search: searchQueryInput.value })
    },
    400,
    { maxWait: 3000 }
)

watch(searchQueryInput, () => {
    debouncedSearch()
})

watch(searchValue, () => {
    searchQueryInput.value = searchValue.value
}, { immediate: true })


function clearAllFilters(): void {
    helpCenterStore.removeCurrentApiParams(['includeChannels', 'includeFeatures', 'includeTypes', 'search'])
}
</script>
