<template>
    <TableFilter
        :name="name"
        :label="label"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        @clear-filter="clearDateFilter"
    >
        <template #icon="{ variant }">
            <FaIcon :icon="icon" :class="variant"></FaIcon>
        </template>
        
        <template #value>
            <div v-if="isFilterActive" class="text-sm max-w-full" :data-testid="`${name}-display`">
                <div class="truncate">
                    <span>{{ formatDate(dateRange?.[0]) }}</span>
                    <FaIcon class="mx-0.5" icon="far fa-arrows-left-right" />
                    <span>{{ formatDate(dateRange?.[1]) }}</span>
                </div>
            </div>
        </template>

        <template #content>
            <div class="flex flex-col my-4 gap-2">
                <div class="flex gap-2 items-center">
                    <InputMask
                        v-model="formattedStartDate"
                        mask="99/99/9999"
                        placeholder="Start Date"
                        class="w-full py-2"
                        @complete="updateDateRangeFromInput('start')"
                        @blur="updateDateRangeFromInput('start')"
                    />
                    <FaIcon icon="far fa-arrows-left-right" />
                    <InputMask
                        v-model="formattedEndDate"
                        mask="99/99/9999"
                        placeholder="End Date"
                        class="w-full py-2"
                        @complete="updateDateRangeFromInput('end')"
                        @blur="updateDateRangeFromInput('end')"
                    />
                </div>
                <DatePicker
                    v-model="dateRange"
                    selection-mode="range"
                    :manual-input="false"
                    inline
                    :placeholder="placeholder"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :number-of-months="1"
                    :pt="{
                        header: {
                            class: 'bg-transparent'
                        },
                        panel: {
                            class: 'bg-transparent border-none'
                        }
                    }"
                />
            </div>
        </template>

        <template #footer>
            <TableFilterFooter v-if="infoText" :info-text="infoText"></TableFilterFooter>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import { ref, computed, watch, inject } from 'vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import TableFilterFooter from '@/components/dataTable/tableFilter/TableFilterFooter.vue'
import { dayjsPlugin } from '@/plugins/dayjs'

const dayjs: typeof dayjsPlugin = inject('dayjs') || dayjsPlugin

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        default: 'far fa-calendar'
    },
    placeholder: {
        type: String,
        default: 'Date Range'
    },
    infoText: {
        type: String,
        default: null
    },
    startDate: {
        type: String,
        default: null
    },
    endDate: {
        type: String,
        default: null
    },
    minDate: {
        type: Date,
        default: null
    },
    maxDate: {
        type: Date,
        default: null
    }
})

const emit = defineEmits(['update-filter', 'remove-filter'])

const dateRange = ref<[Date | null, Date | null] | null>(null)

const formattedStartDate = ref('')
const formattedEndDate = ref('')

export interface DateRangeFilter {
    startDate: string
    endDate: string
}

watch(
    dateRange,
    (newRange) => {
        if (newRange?.[0]) {
            formattedStartDate.value = dayjs(newRange[0]).format('L')
        } else {
            formattedStartDate.value = ''
        }
        if (newRange?.[1]) {
            formattedEndDate.value = dayjs(newRange[1]).format('L')
        } else {
            formattedEndDate.value = ''
        }
        setTimeout(() => {
            if (newRange?.[0] && newRange[1]) {
                const filter: DateRangeFilter = {
                    startDate: dayjs(newRange[0]).format('YYYY-MM-DD'),
                    endDate: dayjs(newRange[1]).format('YYYY-MM-DD')
                }
                emit('update-filter', filter)
            }
        }, 0)
    },
    { immediate: true }
)

const isFilterActive = computed(() => !!dateRange.value?.[0] && !!dateRange.value?.[1])

const formatDate = (date: Date | null | undefined) => (date ? dayjs(date).format('ll') : '')

const clearDateFilter = () => {
    dateRange.value = null
    formattedStartDate.value = ''
    formattedEndDate.value = ''
    emit('remove-filter', props.name)
}

watch(
    () => [props.startDate, props.endDate],
    ([newStartDate, newEndDate]) => {
        dateRange.value = newStartDate && newEndDate ? [dayjs(newStartDate).toDate(), dayjs(newEndDate).toDate()] : null
    },
    { immediate: true }
)

const updateDateRangeFromInput = (type: 'start' | 'end') => {
    const date = type === 'start' ? formattedStartDate.value : formattedEndDate.value
    const parsedDate = dayjs(date, 'L', true)

    if (parsedDate.isValid()) {
        if (type === 'start') {
            dateRange.value = [parsedDate.toDate(), dateRange.value?.[1] || null]
        } else {
            dateRange.value = [dateRange.value?.[0] || null, parsedDate.toDate()]
        }
    }
}
</script>
