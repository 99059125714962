<template>
    <div class="flex justify-center">
        <Button type="button" size="small" label="New tag" rounded @click="toggle" />

        <Popover ref="op" class="bg-gray-100 rounded-2xl shadow border border-surface-950/10 after:content-none">
            <div class="flex flex-col">
                <div class="text-surface-950 text-sm font-normal leading-tight mb-3">Create new tag</div>

                <label for="tagName" class="text-surface-500 text-xs font-normal leading-none mb-1">Name</label>
                <IconField>
                    <InputIcon class="far fa-font-case" />
                    <InputText id="tagName" v-model="tag.name" class="pl-10 text-sm" placeholder="Enter tag name" />
                </IconField>

                <div class="flex justify-end mt-3">
                    <div class="flex gap-2">
                        <Button type="button" size="small" label="Cancel" rounded text @click="toggle" />
                        <Button type="button" size="small" label="Create" rounded @click="createTag" />
                    </div>
                </div>
            </div>
        </Popover>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useTagsStore } from '@/stores/tagsStore'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'

const route = useRoute()
const toast = useToast()

const brandId = route.params.brandId as string

const tagsStore = useTagsStore()

const op = ref()
const tag = ref({
    name: ''
})

const toggle = (event: Event) => {
    op.value.toggle(event)
}

const emit = defineEmits(['tag-created'])

const createTag = async (event: Event) => {
    try {
        await tagsStore.createTag(brandId, { name: tag.value.name })
        toggle(event)
        emit('tag-created')
        toast.add({ severity: 'success', summary: 'Success', detail: 'Tag created successfully', life: 3000 })
    } catch (error: any) {
        console.error(error)
        toast.add({ severity: 'error', summary: 'Failed to create tag', detail: error.message, life: 3000 })
    }
}
</script>
