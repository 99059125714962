<template>
    <header class="h-14 bg-gray-100 flex items-center justify-between px-6 py-2 border-b border-[#030b12]/10">
        <div class="flex items-center gap-2">
            <FaIcon :icon="['fas', 'circle-question']" :class="{ 'text-brand-500': isVideoLibraryActive }" />
            <h1 class="text-surface-800 text-xl font-semibold leading-7">
                <span :class="{ 'text-brand-500': isVideoLibraryActive }"> Help center </span
                >{{ isVideoLibraryActive ? '/ Video library' : '' }}
            </h1>
        </div>
        <div>
            <Button
                label="Request support"
                text
                size="small"
                class="font-semibold leading-tight rounded-full"
                @click="handleRequestSupport"
            />
            <Button
                :pt="{ icon: { class: ['!mr-0'] } }"
                label="Add video"
                icon="far fa-video-plus"
                rounded
                size="small"
                class="font-semibold leading-tight rounded-full"
                @click="handleAddVideo"
            />
        </div>
    </header>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const handleRequestSupport = () => {
    console.log('Request support button clicked')
}

const handleAddVideo = () => {
    console.log('Add video')
}
const route = useRoute()
const isVideoLibraryActive = computed(() => route.path.includes('/video-library'))
</script>

<style scoped></style>
