import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
import type { GetCompanyUsersResp, CompanyUsersApiParams, GetCompanyUsersSummaryResp } from '@/interfaces/CompanyUser'

interface PatchUserPayload {
    active: boolean
}

const fetchUsers = async (companyId: string, params?: CompanyUsersApiParams): Promise<GetCompanyUsersResp> => {
    try {
        const response: AxiosResponse<GetCompanyUsersResp> = await httpClient.get<GetCompanyUsersResp>(
            `/companies/${companyId}/users`,
            {
                params: { ...params }
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const patchUser = async (username: string, payload: PatchUserPayload): Promise<void> => {
    try {
        await httpClient.patch<void>(`/users/${username}`, payload)
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchUsersSummary = async (companyId: string, params?: CompanyUsersApiParams): Promise<GetCompanyUsersSummaryResp> => {
    try {
        const response: AxiosResponse<GetCompanyUsersSummaryResp> = await httpClient.get<GetCompanyUsersSummaryResp>(
            `/companies/${companyId}/users/summary`,
            {
                params: { ...params }
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const exportUsers = async (companyId: string, params?: CompanyUsersApiParams): Promise<string> => {
    try {
        const response: AxiosResponse<string> = await httpClient.get<string>(
            `/companies/${companyId}/users/export`,
            {
                params: { ...params },
                responseType: 'text'
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { fetchUsers, patchUser, fetchUsersSummary, exportUsers }
