import type { App, Plugin } from 'vue'
import dayjs from 'dayjs'

import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

const DayjsPlugin: Plugin = {
    install(app: App) {
        app.config.globalProperties.$dayjs = dayjs
        app.provide('dayjs', dayjs)
    }
}

export { dayjs as dayjsPlugin }
export default DayjsPlugin
