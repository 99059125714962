// src/router/index.ts for the global SPA
import { createRouter, createWebHistory } from 'vue-router'
import { tagsRoutes } from '@/views/TagsView/router'
import { teamDirectoryRoutes } from '@/views/TeamDirectoryView/router'
import TagsApp from '@/views/TagsView/App.vue'
import TeamDirectoryApp from '@/views/TeamDirectoryView/App.vue'
import { loginPageRoutes } from '@/views/LoginPage/router'
import LoginPageApp from '@/views/LoginPage/App.vue'
import { helpCenterViewRoutes } from '@/views/HelpCenterView/router'
import HelpCenterViewApp from '@/views/HelpCenterView/App.vue'

const routes = [
    {
        path: '/',
        redirect: '/tags'
    },
    {
        path: '/tags',
        component: TagsApp,
        children: tagsRoutes,
        redirect: '/brand/2b8ff207-0ceb-43a4-854c-d3d1aa39123c/tags',
        meta: { requiresAuth: true }
    },
    {
        path: '/team-directory',
        component: TeamDirectoryApp,
        children: teamDirectoryRoutes,
        redirect: '/company/cfc6b710-7811-4aa8-803b-93f644a126fb/team-directory',
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        component: LoginPageApp,
        children: loginPageRoutes
    },
    {
        path: '/help-center',
        component: HelpCenterViewApp,
        children: helpCenterViewRoutes,
        meta: { requiresAuth: true }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
