<template>
    <TableFilter
        :name="'user-role'"
        :label="'Role'"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        :has-open-event="true"
        @on-open-filter="emit('get-users-summary-info')"
        @clear-filter="onRemoveFilter"
    >
        <template #icon="{ variant }">
            <FaIcon icon="far fa-list" :class="variant"></FaIcon>
        </template>

        <template #value>
            <div v-if="isFilterActive" class="text-sm m-w-40 text-ellipsis overflow-hidden" data-testid="user-role=filter-display">
                {{ rolesInLabel }}
            </div>
        </template>

        <template #content>
            <div :key="componentKey" class="grid gap-2 my-4 w-full">
                <div
                    v-for="userRole in userRoleOptions"
                    :key="userRole.primaryRole"
                    class="flex align-center w-full text-sm py-1 px-4"
                >
                    <Checkbox
                        v-model="selectedUserRoles"
                        :input-id="userRole.primaryRole"
                        :name="userRole.primaryRole"
                        :value="userRole.primaryRole"
                        :pt="{
                            root: { class: ['m-auto !h-4 !w-4 !py-0 '] },
                            box: { class: ['!h-4 !w-4 !rounded'] }
                        }"
                    />
                    <label :for="userRole.primaryRole" class="flex w-full hover:cursor-pointer">
                        <span class="ml-4">{{
                            userRoleLabels[userRole.primaryRole as keyof typeof userRoleLabels]
                        }}</span>
                        <span v-if="userRole.count !== undefined" class="ml-auto"> {{ userRole.count }}</span>
                        <span v-else class="ml-auto w-6 animate-pulse bg-surface-300 rounded">&nbsp;</span>
                    </label>
                </div>
            </div>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import { computed, ref, watch, type PropType } from 'vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import Checkbox from 'primevue/checkbox'
import { userRoleLabels } from '@/constants'
import type { UserRoleInfo, UsersSummaryInfo } from '@/interfaces/CompanyUser'

const props = defineProps({
    userRoles: {
        type: String,
        required: false,
        default: undefined
    },
    usersSummary: {
        type: Object as PropType<UsersSummaryInfo>,
        required: false,
        default: undefined
    }
})

const emit = defineEmits(['update-user-role-filter', 'remove-user-role-filter', 'get-users-summary-info'])

const userRoleOptions = ref<UserRoleInfo[]>([
    { primaryRole: 'clientadmin', count: undefined },
    { primaryRole: 'submitter', count: undefined },
    { primaryRole: 'client', count: undefined },
    { primaryRole: 'vendor', count: undefined }
])

const selectedUserRoles = ref<string[]>([])

watch(
    () => props.usersSummary,
    (summaryInfo: UsersSummaryInfo | undefined) => {
        if (summaryInfo) {
            userRoleOptions.value.forEach((roleInfo) => {
                const index = summaryInfo.usersByRole.findIndex((opt) => opt.primaryRole === roleInfo.primaryRole)
                roleInfo.count = index > -1 ? summaryInfo.usersByRole[index].count : 0
            })
        }
    }
)

watch(
    () => props.userRoles,
    (payload: string | undefined) => {
        setTimeout(() => {
            if (payload) {
                const newRoles = payload.split(',')
                const isDifferent =
                    newRoles.length !== selectedUserRoles.value.length ||
                    newRoles.some((role, index) => role !== selectedUserRoles.value[index])
                if (isDifferent) {
                    selectedUserRoles.value = newRoles
                }
            } else if (selectedUserRoles.value.length > 0) {
                selectedUserRoles.value = []
            }
        }, 0)
    }
)

const componentKey = ref(0)

watch(
    () => selectedUserRoles.value,
    (roles: string[]) => {
        componentKey.value += 1
        setTimeout(() => {
            emit('update-user-role-filter', { role: roles.length > 0 ? roles.toString() : undefined })
        }, 0)
    }
)

const onRemoveFilter = () => {
    emit('remove-user-role-filter')
}

const isFilterActive = computed(() => {
    return selectedUserRoles.value.length > 0
})

const rolesInLabel = computed(() => {
    return selectedUserRoles.value.map(role => userRoleLabels[role as keyof typeof userRoleLabels]).join(', ')
})
</script>
