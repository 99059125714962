<template>
    <DataView :value="videos" layout="grid" :data-key="'video-library'">
        <template #grid="slotProps">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-12">
                <VideoCard v-for="(item, index) of slotProps.items" :key="index" :video="item" />
            </div>
        </template>
    </DataView>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import VideoCard from './VideoCard.vue'

const videos = ref([
    {
        id: 1,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 2,
        title: 'PerformLine Video 2',
        type: 'training'
    },
    {
        id: 3,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 4,
        title: 'PerformLine Video 2',
        type: 'tuesday-tutorial'
    },
    {
        id: 5,
        title: 'PerformLine Video 1',
        type: 'tuesday-tutorial'
    },
    {
        id: 6,
        title: 'PerformLine Video 2',
        type: 'training'
    },
    {
        id: 7,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 8,
        title: 'PerformLine Video 2',
        type: 'training'
    },
    {
        id: 9,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 10,
        title: 'PerformLine Video 2',
        type: 'tuesday-tutorial'
    },
    {
        id: 11,
        title: 'PerformLine Video 1',
        type: 'tuesday-tutorial'
    },
    {
        id: 12,
        title: 'PerformLine Video 2',
        type: 'tuesday-tutorial'
    },
    {
        id: 13,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 14,
        title: 'PerformLine Video 2',
        type: 'tuesday-tutorial'
    },
    {
        id: 15,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 16,
        title: 'PerformLine Video 2',
        type: 'training'
    },
    {
        id: 17,
        title: 'PerformLine Video 1',
        type: 'training'
    },
    {
        id: 18,
        title: 'PerformLine Video 2',
        type: 'tuesday-tutorial'
    }
])
</script>