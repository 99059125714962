<template>
    <div class="p-12 bg-cover bg-center" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="inset-0 flex flex-col items-center justify-center">
            <div class="text-surface-800 text-4xl font-bold leading-10 mb-8">Help center</div>
            <div class="flex items-center">
                <span class="relative mr-4">
                    <FaIcon
                        icon="far fa-search"
                        class="absolute top-2/4 -mt-2.5 left-3.5 text-xl text-surface-800 dark:text-surface-600"
                    />
                    <InputText
                        v-model="searchQuery"
                        placeholder="Search in video library"
                        class="pl-11 w-[360px] py-2.5 rounded-full"
                        @focus="showSuggestions = true"
                        @blur="handleBlur"
                        @keyup.enter="handleSearch"
                    />
                    <div
                        v-if="showSuggestions"
                        class="absolute w-full mt-1 bg-white rounded-lg shadow-lg border border-surface-200 max-h-[320px] overflow-y-auto"
                        :class="{ 'border-none': searchQuery.length === 0 }"
                    >
                        <div v-if="helpCenterStore.state.isLoading" class="p-3 text-center">Loading...</div>
                        <template v-else>
                            <template v-if="helpCenterStore.filteredSuggestions.length > 0">
                                <div
                                    v-for="suggestion in helpCenterStore.filteredSuggestions"
                                    :key="suggestion"
                                    class="p-3 hover:bg-surface-100 cursor-pointer"
                                    @mousedown.prevent="selectSuggestion(suggestion)"
                                >
                                    <div class="flex items-center">
                                        <FaIcon icon="far fa-clock" class="mr-2 text-surface-600" />
                                        {{ suggestion }}
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="searchQuery">
                                <div
                                    class="p-3 hover:bg-surface-100 cursor-pointer"
                                    @mousedown.prevent="selectSuggestion(searchQuery)"
                                >
                                    <div class="flex items-center">
                                        <FaIcon icon="far fa-clock" class="mr-2 text-surface-600" />
                                        {{ searchQuery }}
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </span>
                <Button label="Search" rounded class="font-semibold" @click="handleSearch" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { useHelpCenterStore } from '@/stores/helpCenter'
import backgroundImage from '@/assets/images/help-center-hero-img.png'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

const helpCenterStore = useHelpCenterStore()
const searchQuery = ref('')
const showSuggestions = ref(false)

const router = useRouter()
const toast = useToast()


const debouncedSearch = useDebounceFn(
    async () => {
        // To Do: What is supposed to happen here?
        helpCenterStore.updateCurrentApiParams({ search: searchQuery.value })
        try {
            await helpCenterStore.fetchVideos()
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: `Failed to fetch videos.`,
                life: 3000
            })
        }
    },
    400,
    { maxWait: 3000 }
)

watch(searchQuery, () => {
    debouncedSearch()
})

const handleSearch = () => {
    helpCenterStore.updateCurrentApiParams({ search: searchQuery.value })
    router.push({ name: 'VideoLibrary' })
}

const selectSuggestion = (suggestion: string) => {
    searchQuery.value = suggestion
    showSuggestions.value = false
    debouncedSearch()
}

const handleBlur = () => {
    // Small delay to allow click events on suggestions
    setTimeout(() => {
        showSuggestions.value = false
    }, 200)
}
</script>

<style scoped>
.p-button-primary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rounded-full {
    border-radius: 9999px;
}
</style>
