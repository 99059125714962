<template>
    <TableFilterDateRange
        name="createdAt"
        label="Created"
        :start-date="createdAtAfter"
        :end-date="createdAtBefore"
        @update-filter="onUpdateFilter"
        @remove-filter="onRemoveFilter"
    />
</template>

<script lang="ts" setup>
import TableFilterDateRange, { type DateRangeFilter } from '@/components/dataTable/tableFilter/TableFilterDateRange.vue'

defineProps({
    createdAtAfter: {
        type: String,
        default: null
    },
    createdAtBefore: {
        type: String,
        default: null
    }
})

const emit = defineEmits(['update-created-at-filter', 'remove-created-at-filter'])

const onUpdateFilter = (filter: DateRangeFilter) => {
    emit('update-created-at-filter', {
        createdAtAfter: filter.startDate,
        createdAtBefore: filter.endDate
    })
}

const onRemoveFilter = () => {
    emit('remove-created-at-filter')
}
</script>
